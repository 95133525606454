.info-title{
    font-size: clamp(2.5rem, 1.5132rem + 5.2632vi, 6.25rem);
    font-weight: 300;
    text-align: center;
    font-family: "Heebo", sans-serif;
    color: #021E39;

}
.info-content{
    font-size: clamp(1.0625rem, 1.0132rem + 0.2632vi, 1.25rem);
    font-family: "Roboto", sans-serif;
    color: #424966;

}