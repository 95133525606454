

@media screen and (max-width:993px){
   .MuiSvgIcon-root.Mui-active{
        margin-top: 0px !important; 
         margin-bottom: 0px !important;
    }
    .MuiSvgIcon-root.MuiStepIcon-root {
        font-size: 13px !important;
        overflow: hidden;
    }
   .MuiStepLabel-root .Mui-active {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    
    .MuiStepLabel-root {
        margin-left: 8px !important;
        margin-right: 0px !important;
    }
}