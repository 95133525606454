.our-team-card {
    height: 410px !important;
}
.our-team-card-content{
    padding: 18px;
    text-align: center;
}
.our-team-card-content h3 {
    font-size: clamp(1.125rem, 0.9605rem + 0.8772vi, 1.75rem);
    line-height: normal;
    font-weight: 500 !important;
    font-family: "Heebo", sans-serif;
    margin-bottom: 0px;
    margin-top: 8px;
}
.our-team-card-content p {
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
    font-weight: 600 !important;
    color: #605f5fbf !important;
    margin-bottom: 0px;
}
.imgjoin{
    width: 40px;
    height: 280px ;
  }
  .imgjoin img{
    width: 100%;
    object-fit: cover;
  }
  @media only screen and (max-width: 993px) {
    .our-team-card{
        margin-bottom: 20px;
        height: 100% !important;
    }

  }
  @media only screen and (max-width: 600px) {
    .our-team-card-content h3 {
        transition: 1s;
     
        line-height: normal;
        font-weight: 600 !important;
    }
    .our-team-card-content p {
     font-size: 13px !important;
        font-weight: 600 !important;
        color: #605f5fbf !important;
    }
    .our-team-card {
        height: 100% !important;
    }
  }