.client-card-title{
    
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    font-weight: 700;
    color: #166CBF;
    font-family: "Heebo" , sans-serif;

}
.client-card-content{
    font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 400;
    color: #424966 ;

}
.client-card{
    border: none !important;
}

@media screen and (max-width:1200px){
    .client-card-image{
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

}

@media screen and (max-width:993px){
    .client-card-image{
        height: 300px;
        object-fit: cover;
    }
    .client-card .ml-30 {

        margin-left: 0px !important;

    }
}

