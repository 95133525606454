.nimaalan-not-found{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nimaalan-not-found img{
   width: min(80%, 500px );
    margin: auto;
}