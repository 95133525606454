@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'react-toastify/dist/ReactToastify.css';
 @media (min-width: 1400px){
     .container{
         max-width: 1380px !important;
    }
}
/* spacing css */
 .mt-10{
     margin-top: 10px ;
}
 .mt-15{
     margin-top: 15px ;
}
 .mt-20{
     margin-top: 20px ;
}
 .mt-25{
     margin-top: 25px ;
}
 .mt-30{
     margin-top: 30px !important;
}
 .mt-35{
     margin-top: 35px ;
}
 .mt-40{
     margin-top: 40px !important;
}
 .mt-45{
     margin-top: 45px ;
}
 .mt-50{
     margin-top: 50px ;
}
 .mt-75{
     margin-top: 75px ;
}
 .mt-100{
     margin-top: 100px !important;
}
 .ml-10{
     margin-left: 10px ;
}
 .ml-15{
     margin-left: 15px ;
}
 .ml-20{
     margin-left: 20px ;
}
 .ml-25{
     margin-left: 25px ;
}
 .ml-30{
     margin-left: 30px ;
}
 .ml-35{
     margin-left: 35px ;
}
 .ml-40{
     margin-left: 40px ;
}
 .ml-45{
     margin-left: 45px ;
}
 .ml-50{
     margin-left: 50px ;
}
 .ml-75{
     margin-left: 75px ;
}
 .ml-100{
     margin-left: 100px ;
}
 .mb-10{
     margin-bottom: 10px ;
}
 .mb-15{
     margin-bottom: 15px ;
}
 .mb-20{
     margin-bottom: 20px ;
}
 .mb-25{
     margin-bottom: 25px ;
}
 .mb-30{
     margin-bottom: 30px ;
}
 .mb-35{
     margin-bottom: 35px ;
}
 .mb-40{
     margin-bottom: 40px ;
}
 .mb-45{
     margin-bottom: 45px ;
}
 .mb-50{
     margin-bottom: 50px ;
}
 .mb-75{
     margin-bottom: 75px ;
}
 .mb-100{
     margin-bottom: 100px ;
}
 .mr-10{
     margin-right: 10px ;
}
 .mr-15{
     margin-right: 15px ;
}
 .mr-20{
     margin-right: 20px ;
}
 .mr-25{
     margin-right: 25px ;
}
 .mr-30{
     margin-right: 30px ;
}
 .mr-35{
     margin-right: 35px ;
}
 .mr-40{
     margin-right: 40px ;
}
 .mr-45{
     margin-right: 45px ;
}
 .mr-50{
     margin-right: 50px ;
}
 .mr-75{
     margin-right: 75px ;
}
 .mr-100{
     margin-right: 100px ;
}
 .mt-130{
     margin-top: 130px !important;
}
 .pt-10{
     padding-top: 10px ;
}
 .pt-15{
     padding-top: 15px ;
}
 .pt-20{
     padding-top: 20px ;
}
 .pt-25{
     padding-top: 25px ;
}
 .pt-30{
     padding-top: 30px ;
}
 .pt-35{
     padding-top: 35px ;
}
 .pt-40{
     padding-top: 40px ;
}
 .pt-45{
     padding-top: 45px ;
}
 .pt-50{
     padding-top: 50px ;
}
 .pt-75{
     padding-top: 75px ;
}
 .pt-100{
     padding-top: 100px ;
}
 .pb-10{
     padding-bottom: 10px ;
}
 .pb-15{
     padding-bottom: 15px ;
}
 .pb-20{
     padding-bottom: 20px ;
}
 .pb-25{
     padding-bottom: 25px ;
}
 .pb-30{
     padding-bottom: 30px ;
}
 .pb-35{
     padding-bottom: 35px ;
}
 .pb-40{
     padding-bottom: 40px ;
}
 .pb-45{
     padding-bottom: 45px ;
}
 .pb-50{
     padding-bottom: 50px ;
}
 .pb-75{
     padding-bottom: 75px ;
}
 .pb-100{
     padding-bottom: 100px ;
}
 .pr-10{
     padding-right: 10px ;
}
 .pr-15{
     padding-right: 15px ;
}
 .pr-20{
     padding-right: 20px ;
}
 .pr-25{
     padding-right: 25px ;
}
 .pr-30{
     padding-right: 30px ;
}
 .pr-35{
     padding-right: 35px ;
}
 .pr-40{
     padding-right: 40px ;
}
 .pr-45{
     padding-right: 45px ;
}
 .pr-50{
     padding-right: 50px ;
}
 .pr-75{
     padding-right: 75px ;
}
 .pr-100{
     padding-right: 100px ;
}
 .pl-10{
     padding-left: 10px ;
}
 .pl-15{
     padding-left: 15px ;
}
 .pl-20{
     padding-left: 20px ;
}
 .pl-25{
     padding-left: 25px ;
}
 .pl-30{
     padding-left: 30px ;
}
 .pl-35{
     padding-left: 35px ;
}
 .pl-40{
     padding-left: 40px ;
}
 .pl-45{
     padding-left: 45px ;
}
 .pl-50{
     padding-left: 50px ;
}
 .pl-75{
     padding-left: 75px ;
}
 .pl-100{
     padding-left: 100px ;
}
 :root{
     --primary : #166cbf;
     --heebo : "Heebo", sans-serif;
     --Open-sans: "Open Sans", sans-serif;
     --Roboto : "Roboto", sans-serif;
     --Lato : "Lato", sans-serif;
     --page-color : #F8FFF6;
     --primary-hover: #115390;
}
 body{
     background-color: var(--page-color);
}
 .primary-btn{
     height: 40px !important;
     width: 147px !important;
     background-color: var(--primary) ;
     border: 1.4px solid var(--primary) ;
     color: #fff !important;
     border-radius: 5px;
     font-family: var(--Open-sans);
     font-weight: 700;
}
 .primary-btn:hover{
     background-color: var(--primary-hover);
}
/* navbar css */
 .nimalaan_custom_nav{
     position: sticky;
     top: 18px;
     z-index: 999;
}
.nimalaan_custom_nav_bg{
    position: sticky;
    top: 0px;
    z-index: 999;
}
 .navber_custom{
     background-color: #fff !important;
     height: 80px;
     box-shadow: 0px 2px 6px 0px #0000000D;
     padding: 20px;
     border-radius: 20px;
}
 .nimalaan-logo{
     height: 60px;
     width: 113px;
}
 .navbar-nav .nav-link{
     font-family: var(--Roboto);
     font-weight: 500;
     color: #232323;
     font-size: clamp(0.875rem, 0.8257rem + 0.2632vi, 1.0625rem);
     margin: 0px 12px;
}
 .offcanvas-header{
     border-bottom: 1.4px solid #ebebeb;
     padding: 12px;
}
.nav-link:hover{
    color: #166CBF !important;
}
.nav-link.active{
    color: #166CBF !important;
}
/* banner css */
 .nimalaan_banner{
     height: 680px;
     width: 100%;
     position: relative;
}
 .nimalaan_banner_inner_section{
    /* height: 100%;
     */
     z-index: 9;
     position: relative;
     width: 100%;
}
 .nimalaan_banner_inner_section h1{
     font-size: clamp(1.875rem, 1.4199rem + 2.4272vi, 3.75rem);
     font-family: var(--heebo);
     font-weight: 700;
     line-height: 77px;
     color: #021E39;
}
 .nimalaan_banner_inner_section p{
     font-size: clamp(1.0625rem, 1.017rem + 0.2427vi, 1.25rem);
     font-family: var(--Roboto);
     font-weight: 400;
     color: #424966;
     margin-top: 20px;
}
 .nimaalan-powering-banner-image{
     position: absolute;
     top: 0;
     right: 0;
     height: 630px;
}
/* clients */
 .nimalaan-main-section{
     margin-top: 50px;
}
 .clients_card{
     background-color: #fff !important;
     width: 100%;
     height: 166px;
     box-shadow: 0px 2px 6px 0px #0000000D;
     border-radius: 50px;
     margin-top: 50px;
}
 .nimalaan-section-title{
     font-size: clamp(1.25rem, 0.9466rem + 1.6181vi, 2.5rem);
     font-family: var(--heebo);
     font-weight: 700;
     color: #021E39;
     margin-top: 0px;
     margin-bottom: 32px;
     text-align: center;
     line-height: 52px;
}
.contact_title{
    font-size: clamp(1.25rem, 0.9466rem + 1.6181vi, 2.5rem);
    font-family: var(--heebo);
    font-weight: 700;
    color: #021E39;
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: center;
}
 .nimalaan-section-title span{
     font-weight: 400 !important;
}
 .clients_card_row{
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100%;
     width: 100%;
     padding: 22px 0px;
}
 .clients_card_col{
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100%;
     width: 100%;
     flex-basis: 20%;
}
 .clients_card .clients_card_row .clients_card_col {
     border-right: 1px solid #f4f4f4;
     height: 100%;
}
 .clients_card .clients_card_row .clients_card_col:last-child{
     border-right: none !important;
}
 .clients_card .clients_card_row .clients_card_col:first-child img, .clients_card .clients_card_row .clients_card_col:last-child img{
     height: 88px;
}
 .clients_card .clients_card_row .clients_card_col:nth-child(2) img{
     height: 78px;
}
 .clients_card .clients_card_row .clients_card_col:nth-child(3) img{
     height: 74px;
}
 .clients_card .clients_card_row .clients_card_col:nth-child(4) img{
     height: 103px;
}
.text-information{
    font-weight: 400;
    font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
    line-height: 27px;
    color: #424966 !important;
    font-family: var(--Roboto);
}
/* inovation */
 .section-sub-container p {
     font-size: clamp(0.9375rem, 0.892rem + 0.2427vi, 1.125rem);
    ;
     font-family: var(--Roboto);
     font-weight: 400;
     color: #424966;
}
 .innovation_card{
     height: 153px;
     width: 100%;
     border-radius: 25px;
     background-color: #fff;
     box-shadow: 0px 2px 6px 0px #0000000D;
     display: flex;
     align-items: center;
     justify-content: center;
}
 .innovation_card h2{
     font-size: clamp(1.25rem, 0.9466rem + 1.6181vi, 2.5rem);
     font-family: var(--heebo);
     font-weight: 700;
     color: #8FBB7D;
     margin: 0;
}
 .innovation_card h6{
     font-family: var(--Open-sans);
     font-weight: 600;
     font-size: clamp(1.0625rem, 1.017rem + 0.2427vi, 1.25rem);
     color: #424966;
     text-align: center;
     margin-top: 5px;
     margin-bottom: 0;
}
 .innovation_card h6 span{
     color: #021E39;
     text-align: center;
     font-weight: 700;
}
 .nimalaan_image_section{
     position: relative;
     background-image: url('./assets/images/turnkey-development-of-renewable-energy-projects.png');
     height: 572px;
     width: 100%;
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
     border-radius: 20px;
     box-shadow: 0px 2px 6px 0px #0000000D;
}
 .nimalaan_image_section_content{
     padding-left: 60px;
     padding-top: 60px;
}
 .nimalaan_product_management{
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 20px;
     background-color: #fff;
     border-radius: 10px;
     border: 1px solid #EAEAEA;
     gap: 20px;
}
 .nimalaan_product_management svg{
     flex-basis: 40%;
}
 .nimalaan_product_management h5{
     font-size: clamp(1.275rem, 1.1368rem + 0.7368vi, 1.8rem);
     font-family: var(--heebo);
     font-weight: 700;
     color: #021E39;
}
 .nimalaan_product_management h6{
     font-size:  clamp(0.8125rem, 0.7796rem + 0.1754vi, 0.9375rem);
     font-family: var(--Lato);
     font-weight: 400;
     color: #424966;
     opacity: 75%;
     line-height: 22px;
}
 .nimalaan_renwable h1{
     font-size: clamp(1.25rem, 0.9466rem + 1.6181vi, 2.5rem);
    ;
     font-family: var(--heebo);
     font-weight: 500;
     color: #021E39;
}
/* our-service */
 .our-service-card{
     height: 455px;
     width: 100%;
     background-color: #fff;
     border-radius: 25px;
     box-shadow: 0px 2px 6px 0px #0000000D;
}
 .our-service-card-content{
     padding: 24px;
}
 .our-service-card-content h3{
     margin-top: 0px;
     font-size: clamp(1.125rem, 1.034rem + 0.4854vi, 1.5rem);
     font-family: var(--heebo);
     font-weight: 600;
     color: #021E39;
     line-height: 32px;
}
 .our-service-card-content ul li {
     font-size: clamp(0.875rem, 0.8447rem + 0.1618vi, 1rem);
     font-family: var(--Lato);
     color: #424966;
     font-weight: 400;
}
 .nimalaan-section-subtitle{
     font-size: clamp(0.875rem, 0.8447rem + 0.1618vi, 1rem);
     font-weight: 400;
     font-family: var(--Roboto);
     color: #424966;
     margin-bottom: 24px;
}
 .nimalaan-section-title.withcard{
     margin-bottom: 24px;
}
/* our missions */
 .color-shade{
     height: 559px;
     width: 40%;
     background-color: #EAF5FF;
     position: absolute;
     left: 0;
     z-index: -1;
     top: 0;
}
 .ourservie_img{
     height: 422px;
}
 .image-card-title{
     font-family: var(--heebo);
     font-weight: 800;
     font-size: clamp(1.0625rem, 0.9474rem + 0.614vi, 1.5rem);
     color: #1f398f;
     margin-top: 20px;
}
 .image-card-main{
     font-family: var(--heebo);
     font-weight: 700;
     font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
     color: #021E39;
     margin-top: 25px;
     line-height: 52px;
}
.image-content-card{
    font-family: var(--heebo);
    font-weight: 700;
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    color: #021E39;
    margin-top: 0px;
    line-height: 52px;
}
 .image-card-content {
     font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
     font-weight: 400;
     color: #424966;
     font-family: var(--Roboto);
     margin-top: 20px;
     line-height: 27px;
     padding-right: 24px;
     text-align: justify;
}
 .map_card_img{
     border-radius: 25px;
     margin-top: 18px;
}
 .our-achievements{
     padding-left: 30px;
}
 .achievement_card{
     width: 100%;
     background: #fff ;
     box-shadow: 0px 2px 6px 0px #0000000D;
     padding: 22px;
     border-radius: 10px;
}
 .achievement_card_title{
     font-size: clamp(1.1875rem, 1.0395rem + 0.7895vi, 1.75rem);
     font-family: var(--heebo);
     margin: 0;
     font-weight: 700;
     color: #8FBB7D;
}
 .achievement_card_subtitle{
     font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
     font-family: var(--heebo);
     margin: 0;
     font-weight: 700;
     color: #021E39;
     margin-top: 12px;
}
 .achievement_card_content{
     font-size: clamp(0.875rem, 0.8257rem + 0.2632vi, 1.0625rem);
     font-family: var(--Roboto);
     margin: 0;
     font-weight: 400;
     color: #424966;
     margin-top: 12px;
}
.achievement_card_about{
    width: 100%;
    background: #fff ;
    box-shadow: 0px 2px 6px 0px #0000000D;
    padding: 22px;
    border-radius: 10px;
    height: 160px !important;
}
.whatwedo_card_about{
    width: 100%;
    background: #fff ;
    box-shadow: 0px 2px 6px 0px #0000000D;
    padding: 22px;
    border-radius: 10px;
    height: 200px !important;
    margin-bottom: 0px;
}
/* contact */
 .contact_card{
     width: 100%;
     background-color: #fff;
     box-shadow: 0px 2px 6px 0px #0000000D;
     padding: 22px;
     border-radius: 10px;
     display: flex;
     align-items: center;
     justify-content: center;
     height: 266px;
}
 .contact_card svg{
     height: 79px;
     width: 79px;
}
 .contact_card p {
     font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
     font-family: var(--Open-sans);
     font-weight: 400;
     color: #000;
     opacity: 75%;
     margin-top: 18px;
     margin-bottom: 0px;
}
 .contact_card h5{
     font-size: clamp(1.125rem, 1.0592rem + 0.3509vi, 1.375rem);
     font-weight: 600;
     font-family: var(--heebo);
     margin: 0px;
     color: #021E39;
     margin-top: 18px;
     margin-bottom: 0px;
}
/* footer */
 .footer-logo{
     width: 166px;
     height: 98px;
}
 .links_head{
     font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
     font-weight: 700;
     font-family: var(--Open-sans);
     color: #0E0A00;
}
 .links a {
     display: block;
     text-decoration: none;
     font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
     font-weight: 400;
     font-family: var(--Open-sans);
     color: #0E0A00;
     opacity: 75%;
     margin-bottom: 12px;
}
 .links a:hover, .address_box p a:hover{
     color: var(--primary-hover);
}
 .address_box{
     display: flex;
}
 .address_box:first-child svg{
     flex-basis: 25%;
}
 .address_box svg{
     flex-basis: 20%;
     height: 35px;
     width: 35px;
     color: #4B4840 !important;
}
 .address_box p {
     margin: 0px;
     font-size: clamp(1.0625rem, 1.0132rem + 0.2632vi, 1.25rem);
     font-weight: 600;
     font-family: var(--Open-sans);
     color: #0E0A00;
     opacity: 75%;
}
 .address_box p a{
     text-decoration: none;
     color: #0E0A00;
}
 .social_links{
     display: flex;
     align-items: center;
    /* justify-content: space-between;
     */
     gap: 25px;
     margin-top: 20px;
}
 .social_links a {
     height: 32px;
     width: 32px;
     background-color: #e2f3ff;
    /* opacity: 10%;
     */
     color: #1072B8;
     font-size: 18px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 50%;
     transition: 0.30s all ease;
}
.social_links a:hover{
    transform: scale(0.8);
}
 .copy-rights {
     display: flex;
     align-items: center;
     justify-content: space-between;
     border-top: 2px solid #f1f1f1;
     padding: 32px 0px;
     margin-top: 32px;
}
 .copy-rights p{
     font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
     font-weight: 600;
     font-family: var(--Roboto);
     margin: 0px;
}
.copy-rights p a{
    font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
    font-weight: 600;
    font-family: var(--Roboto);
    margin: 0px;
    text-decoration: none;
    color: #333333;
}
.copy-rights p a:hover{
    color: var(--primary-hover);
}
/* join-now */
 .join-now-card{
     background-color: #fff;
     padding: 42px 38px;
     border: 1px solid #CCEAC4;
     border-radius: 10px;
}
.primary-btn.submit{
    height: 42px !important;
    font-size: 17px;
}
/* customized toaster */
.Toastify__toast-container--top-right {
    right: 8em !important;
}
.Toastify__toast {
    width: 420px !important;
}
.Toastify__toast {
    padding: 0 !important;
    border-radius: 10px !important;
}
.Toastify__toast-icon {
    height: 100% !important;
    width: 55px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.Toastify__toast-icon svg {
    height: 24px !important;
    width: 24px !important;
    margin: auto !important;
}
.Toastify__toast-body {
    padding: 0px !important;
    margin: 0px !important;
}
.Toastify__toast--success .Toastify__toast-icon {
    background-color: #6ac649 !important;
}
.Toastify__toast--error .Toastify__toast-icon {
    background-color: #e46767 !important;
}
.Toastify__toast--info .Toastify__toast-icon {
    background-color: #4090ef !important;
}
.Toastify__toast--warning .Toastify__toast-icon {
    background-color: #e0ad46 !important;
}
.Toastify__toast-body > div:last-child {
    color: #1f2328;
    font-size: 16px;
    font-family: var(--heebo) , sans-serif;
    font-weight: 600;
}
.Toastify__close-button {
    align-self: center !important;
    margin-right: 10px !important;
}
.Toastify__toast-container {
    z-index: 99999 !important;
}
/* forms */
 .form-label {
     font-size: clamp(0.9375rem, 0.8882rem + 0.2632vi, 1.125rem);
     font-weight: 700;
     font-family: var(--heebo);
     color: #333333;
}
 .required{
     color:#ef5454 !important;
}
 .form-control{
     padding: 16px 20px;
     font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
     font-weight: 500;
     font-family: var(--heebo);
     border-radius: 0px !important;
}
.form-control:hover, .form-select:hover{
    border: 1px solid black;
}
 .form-control::placeholder{
     font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
     font-weight: 400;
     font-family: var(--heebo);
     color: #878787;
}
 button.w-100{
     width: 100% !important;
}
.form-select{
  padding: 16px 20px;
  font-size: 17px;
  font-weight: 600;
  font-family: var(--heebo);
  border-radius: 0px !important;
}
.form-select option[disabled]{
  font-size: 17px ;
  font-weight: 400;
  font-family: var(--heebo);
  color: #878787 !important;
}
.contact_us_page .nimalaan_banner{
    height: 100% !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.social-media{
    padding-left: 50px !important;
   }

   input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
/* .MuiStepLabel-root{
    margin-left: -1px !important;
    margin-right: -1px !important;
}  */

.breadcrumb-item a{

    font-family: var(--Roboto);
    font-weight: 700;
    color: #383838;
    text-decoration: none;


}
.breadcrumb-item a:hover{
    color: #166CBF; 
}
.breadcrumb-item+.breadcrumb-item::before{
    content: var(--bs-breadcrumb-divider, "\203A") !important;
}

.custom_beadcrumb{
    margin-top: 25px;
    margin-bottom: 35px;
}
.breadcrumb-item.active{
    color: #166CBF;
    font-family: var(--Roboto);
    font-weight: 700;
    text-decoration: none;

}
.carrier_banner{
    background-color: #fff;
    height: 254px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.carrier_banner_content p {

    font-size: clamp(1.125rem, 1.0263rem + 0.5263vi, 1.5rem);
    font-family: var(--heebo);
    font-weight: 500;
    line-height: 34px;
    color: #646464;
    margin: 0px ;

}
.carrier_banner_content h1{
    font-size: clamp(1.875rem, 1.3816rem + 2.6316vw, 3.75rem);
    font-family: var(--heebo);
    line-height: 106px;
    color: #131313;
    opacity: 85%;
    margin-bottom: 0px;
    margin-top: 5px;

}

.upload_container{
    display: flex;
    align-items: center;
    border: 1.6px solid #dee2e6;
    height: 100px;
    padding: 26px;
    gap: 20px;
    cursor: pointer;
}
.upload_container:hover{
    border: 1px solid black;
}
.upload_container h5{
    margin: 0px;
    font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
    font-family: var(--heebo);
    line-height: 29px;
    color: #333333;
    font-weight: 700;

}

.upload_container img{
    height: 60px;
    width: 41px;
}
.upload_container p{
    margin-bottom: 0px;
    margin-top: 0px;
    /* font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem); */
    font-size: 14px;
    font-family: var(--heebo);
    line-height: 24px;
    color: #878787;
    font-weight: 400;
}

.apply_now_title{
    margin: 0px;
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    font-family: var(--heebo);
    line-height: 60px;
    color: #021E39;
    font-weight: 700;
    margin-bottom: 50px;
}

.apply_now_section{
    margin-top: 50px;
}
.jobs_section{
    height: 100%;
    width: 100%;
    background: #fff;
    padding: 28px;
    margin-top: 50px;
}

.jobs_section h1{
    margin: 0px;
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    font-family: var(--heebo);
    line-height: 60px;
    color: #021E39;
    font-weight: 700;
    margin-bottom: 50px;
}
.job_card{
    height: 489px;
    width: 100%;
    background: #F4FAFF;
    padding: 32px;
    border: 1px solid transparent;
    transition: 0.15s all ease-in-out;

}
.job_card:hover{
  border: 1px solid #4C92D6;
}

.job_title{
    font-size: clamp(1.125rem, 0.9605rem + 0.8772vi, 1.75rem);
    line-height: 41px;
    font-weight: 700;
    font-family: var(--heebo);
    color: #131313;
    opacity: 85%;
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.job_keys{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 30px; */
}
.job_keys_cont{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-right: 20px;
    margin-bottom: 14px;
}

.job_keys_cont p{
    margin: 0px;
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
    line-height: 28px;
    font-weight: 600;
    font-family: var(--Roboto);
    color: #131313;
    opacity: 75%;
}

.job_description{
    font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
    line-height: 28px;
    font-weight: 500;
    font-family: var(--Roboto);
    color: #666666;
    margin-top: 20px;
    overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 6;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
}

.apply_now_btn{
    all: unset;
    width: 147px;
    height: 40px;
    border-radius: 5px;
    background: var(--primary);
    color: #fff;
    font-family: var(--Open-sans);
    font-weight: 700;
    font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
    text-align: center;

}
.apply_now_btn:hover{
    background-color: var(--primary-hover);
}

.detail_button{
    all: unset;
    display: inline-block;
    width: 217px;
    height: 40px;
    border-radius: 5px;
    background: transparent;
    color: #343536;
    font-family: var(--Open-sans);
    font-weight: 600;
    font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
    text-align: center;
    border: 1px solid #000000

}
.detail_button.anchor{
    padding: 6px 0px;
    height: unset;
    cursor: pointer;
}
.action_buttons{
    /* margin-top: 24px !important; */
}
.detail_button:hover{
    background-color: #343536;
    color: #fff;
}
.job_column{
    margin-bottom: 32px;
}
.job_cont{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.w-100{
    width: 100%;
}

/* Job view  */

.job-view-section .post_date{
    font-size: 14px;
    font-family: var(--Roboto);
    line-height: 28px;
    font-weight: 700;
    color: #717171;
}
.job_overview{
    margin-top: 35px;
}
.job_overview p{
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
    font-family: var(--Roboto);
    line-height: 28px;
    font-weight: 500;
    color: #717171;
    margin-top: 0px;
    margin-bottom: 10px;
}
.job_overview p:last-child{
    margin-bottom: 0px;
}
.job_overview p span{
    
    font-weight: 700;
    color: #131313;
}
.job_view_description{
    margin-top: 35px;
}

.job_view_description h5{
    font-size: clamp(1rem, 0.9342rem + 0.3509vi, 1.25rem);
    line-height: 29px;
    font-weight: 700;
    font-family: var(--heebo);
    color: #131313;
    opacity: 85%;
    margin: 0px;

}
.job_view_description p{
    /* font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem); */
    font-family: var(--Roboto);
    line-height: 28px;
    /* font-weight: 500; */
    color: #666666;
    /* margin-top: 20px; */
    /* margin-bottom: 0px; */
}
.job_view_description ol {
    margin-top: 20px;
}
.job_view_description ol li{
    /* font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem); */
    font-family: var(--Roboto);
    line-height: 28px;
    /* font-weight: 500; */
    color: #666666;
    /* margin-top: 6px; */
    /* margin-bottom: 0px; */
}
.job_view_description ul li{
    /* font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem); */
    font-family: var(--Roboto);
    line-height: 28px;
    /* font-weight: 500; */
    color: #666666;
    /* margin-top: 6px; */
    /* margin-bottom: 0px; */
}

.job-view-btn{
    all: unset;
    width: 221px;
    height: 40px;
    border-radius: 5px;
    background: var(--primary);
    color: #fff;
    font-family: var(--Open-sans);
    font-weight: 700;
    font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
    text-align: center;
    margin-top: 40px;
}
.job-view-btn:hover{
    background-color: var(--primary-hover);
}
.job-info-section{
    margin-top: 40px;
}
.job-info-overview{
    margin-top: 26px;
}
.job-info-section-container{
  margin-bottom: 20px;
}
.job-info-section-container span{
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
    font-family: var(--Roboto);
    line-height: 32px;
    font-weight: 400;
    color: #000000;
    opacity: 75%;
}
.job-info-section-container h5{
    font-size: clamp(0.875rem, 0.8092rem + 0.3509vi, 1.125rem);
    font-family: var(--Roboto);
    line-height: 32px;
    font-weight: 700;
    color: #000000;
    opacity: 75%;
    margin: 0px;
    margin-top: -4px;
}
.job_detail_main{
    margin-top: 50px;
}
.carrier_banner .job-view-btn{
    margin-top: 14px !important;
}

/* Events Card */

.featured-events{
    background: #fff;
    padding: 30px;
}

.featured-events h1{
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    line-height: 41px;
    font-weight: 700;
    font-family: var(--heebo);
    color: #021E39;
    margin: 0px;
    margin-top: 10px;
}

.event_cont{
    padding: 20px;
    background: #F4FAFF;
    min-height: 290px;
}

.event_card{
    border-radius: 30px !important;
    overflow: hidden;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.event_card:hover{
    box-shadow: 0px 5px 15px #d2d2d275;
}

.event_card .event-img{
    width: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.event_title{
    font-size: clamp(1.125rem, 0.9605rem + 0.8772vi, 1.75rem);
    font-weight: 700;
    font-family: var(--heebo);
    color: #131313;
    opacity: 85%;
    margin: 0px;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.event_keys{
    margin: 18px 0;
}

.event_keys_cont{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.event_keys_cont p{
    margin-bottom: 0;
    padding-left: 6px;
    color: #7A7A7AD9;
    white-space: nowrap;
}

.event_description{
    font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
    line-height: 28px;
    font-weight: 500;
    font-family: var(--Roboto);
    color: #666666;
    text-wrap: balance;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.event_banner{
    height: 350px;
    object-fit: cover;
    border-radius: 30px;
}

.video_thumb{
    position: relative;
    transition: .3s ease-in-out;
    border-radius: 5px;
    overflow: hidden;
}

.video_thumb .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.7;
    z-index: 99;
    top: 0;
    left: 0;
    
}

.video_thumb .play_icon{
    color: white;
    position: absolute;
    font-size: 70px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.hover-effect:hover a .video_thumb {
    transform: scale(0.96);
}
.hover-effect:hover a .video_thumb img {
    transform: unset;
}

.action_button{
    margin: 12px 0;
}

.action_button .explore-event{
    font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
    color: #166CBF;
    font-family: var(--Roboto);
    font-weight: 500;
    text-decoration: underline;
}

.action_button .explore-event:hover{
    color: #0080f8 !important;
}

.event-content{
    margin-top: 30px;
}

.event-content h1{
    font-size: clamp(1.25rem, 0.9466rem + 1.6181vi, 2.5rem);
    font-family: var(--heebo);
    font-weight: 700;
    color: #021E39;
    margin-top: 0px;
    margin-bottom: 10px;
}

.event_timing{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.event_location p{
    font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem);
    line-height: 28px;
    font-weight: 500;
    font-family: var(--Roboto);
    color: #021E39;
    text-wrap: balance;
    margin-bottom: 0px;
    padding-left: 8px;
}

.event_location{
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding-bottom: 20px;
}

.event-descrp{
    margin: 20px 0;
}

.event-descrp p{
    /* font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem); */
    line-height: 28px;
    /* font-weight: 500; */
    font-family: var(--Roboto);
    text-wrap: balance;
    margin-bottom: 0px;
    color: #666666;
    /* padding-bottom: 26px; */
    /* white-space: normal; */
}

.event-descrp ul{
    padding-left: 18px;
}

.event-descrp li{
    /* font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.125rem); */
    line-height: 28px;
    /* font-weight: 500; */
    font-family: var(--Roboto);
    /* text-wrap: balance; */
    /* margin-bottom: 0px; */
    color: #515151;
}

.event-detail-banner{
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 0;
}

/* .event-gallery{
    padding-top: 50px;
} */

.event-gallery h1{
    font-size: clamp(1.25rem, 0.9466rem + 1.6181vi, 2.5rem);
    font-family: var(--heebo);
    font-weight: 700;
    color: #021E39;
    margin-top: 0px;
    padding-top: 30px;
    margin-bottom: 40px;
    text-align: center;
}

.event-gallery a{
    padding-bottom: 20px;
}

.event-gallery a img{
    width: 100%;
    transition: .3s ease-in-out;
    height: 275px;
    object-fit: cover;
}

/* .hover-effect{
    transition: .3s ease-in-out;
} */

.hover-effect:hover a img{
    transform: scale(0.96);
}

.view-more-btn{
    height: 40px !important;
    width: 180px !important;
    background-color: #fff;
    border: 1.4px solid #343536;
    color: #343536 !important;
    border-radius: 5px;
    font-size: 18px;
    font-family: var(--Open-sans);
    font-weight: 700;
    transition: .3s ease-in-out;
}
.view-more-btn:hover{
    background-color: #343536;
    color: #fff !important;
}
.view-more-btn .arrow-icon{
    font-size: 24px;
}
.chip-group{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 12px;
}
.chip{
    background: #ffffff !important;
    border: 1px solid #ccf3d6;
    padding: 2px 20px 4px 20px !important;
    border-radius: 30px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-right: 8px !important;
    margin-bottom: 8px !important;
    line-height: 28px !important;
}

.no_data_message{
    text-align: center;
}

.no_data_message p{
    color: #021E39;
    font-size: clamp(1rem, 0.9671rem + 0.1754vi, 1.5rem);
    font-family: var(--heebo);
    line-height: 28px;
    font-weight: 400;
    padding: 20px 0;
}

@media screen and (max-width: 991px) {
    .no_data_message img{
        width: 100%;
    }
    .event_card .event-img{
        height: 200px;
    }
    .no_gallary .no_data_message img{
        width: 70%;
    }
}
/* loader */

.page_loader_wrapper{
    height: 100%;
    width: 100%;
    position: fixed;
    inset: 0;
    background-color: #0000001e;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    
   
}
.loader_box{
    height: 100px;
    width: 100px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

}

.infinite-scroll-component {
    overflow: unset !important;
}

.loader_div .spinner-border{
    height: 20px;
    width: 20px;
    color: var(--primary);
}
.nojobs{
    height: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.uploaded_file{
    margin-top: 13px;
}
.clear_resume{
    color: rgb(255, 255, 255);
    cursor: pointer;
    height: 15px;
    width: 15px;
    background-color: #c29e9e;
    border-radius: 50%;
    position: relative;
    left: 6px;
    top: -2px;
}
.chip_design{
    padding: 5px 15px;
    background: #edf5fc;
    border-radius: 20px;
    border: 1px solid #89bdef;
    display: inline-block;
    margin-left: 10px;

}

.no_gallary{
    width: 100%;
    display: flex;
    justify-content: center;
}