.slick-next {
    display: none !important;
    cursor: none !important;
}
.slick-prev {
    display: none !important;
    cursor: none !important;
}
.custom-arrows{
   position: absolute;
   top: 50% ;
   left: 49.75% ;
    transform: translate(-50% , -50%);
    z-index: 99;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .custom-arrows .arrow{
    font-size: 18px;
    height: 50px;
    width: 50px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    color: #166CBF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-arrows .arrow:hover{

    background-color: rgb(240, 240, 240);

  }
  .slick-next:before {
    content: '';
    display: none;
}
.slick-slide > div  {
margin: 0px 10px;
}

/* @media only screen and (max-width: 600px) {
  .custom-arrows .arrow{
    display: none;
  }
}
 */

/* @media only screen and (max-width: 1400px) {
  .custom-arrows .arrow{
    display: none;
  };
 
} */
@media only screen and (max-width: 1380px) {
  .custom-arrows {
    width: 88%;
  }
  .custom-arrows .arrow {
    height: 25px;
    width: 25px;
    padding: 3px;
  }
  
}
@media only screen and (min-width: 1350px){
  .text-content{
    padding-left: 15px !important;
  }
  .img-slider{
    margin-right: 40px;
  }
}