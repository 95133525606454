@import 'react-loading-skeleton/dist/skeleton.css';
/* for large monitors */
@media screen and (min-width:2500px) and (max-width:2560px) {
    .nimaalan-powering-banner-image {
        right: 20%;
    }
}

@media screen and (min-width:1900px) and (max-width:2500px) {
    .nimaalan-powering-banner-image {
        right: 6%;
    }
}

/* for laptops */

@media screen and (min-width:1200px) and (max-width:1380px) {
    .nimalaan_banner {
        height: 500px;
    }

    .nimalaan-section-title {
        margin-bottom: 10px;
    }

    .nimalaan_banner_inner_section .mt-130 {
        margin-top: 60px !important;
    }

    .nimaalan-powering-banner-image {
        height: 480px;
    }

    .clients_card .clients_card_row .clients_card_col img {
        height: 62px !important;
    }

    .clients_card {
        height: 125px;
    }

    .our-service-card {
        height: 430px;

    }

    img.service {
        object-fit: cover;
        border-radius: 20px;
    }

    .achievement_card {
        height: 100%;
    }

    .service_page_card_image img {
        object-fit: cover;
        border-radius: 20px;
    }
}

@media screen and (max-width:1200px) {
    .address_box p {
        font-size: 15px !important;
    }

}

@media screen and (min-width:1400px) {
    .social-media {
        padding-left: 100px !important;
    }

}

@media screen and (max-width:1200px) {
    .social-media {
        padding-left: 10px !important;
    }

}

@media screen and (min-width: 1100px) and (max-width:1200px) {
    .nimaalan-powering-banner-image {
        height: 435px;
    }
}

/* ------------------------------------------------------------------------ */
/* for Tablet */
@media screen and (max-width:1056px) {
    .image-card-main {
        line-height: 28px;
    }

    .nimalaan_banner_inner_section .mt-130 {
        margin-top: 55px;
    }

    .text-information {
        line-height: 25px;
    }

    .MuiBox-root {
        width: 100% !important;
    }

    .nimalaan-section-title {
        line-height: 30px;
    }

    .nimaalan-powering-banner-image {
        position: absolute;
        top: 0% !important;
        right: 0% !important;
        height: 400px !important;
    }

    .nimalaan_banner_inner_section h1 {
        line-height: 60px;
    }

    .nimalaan_banner {
        height: 450px !important;
    }

    .clients_card {
        height: 100px;
        overflow: hidden;
        margin-top: 18px;
    }

    .clients_card .clients_card_row .clients_card_col img {
        height: 50px !important;
    }

    .clients_card_row {
        padding: 12px 0px;
    }

    .innovation_card {
        height: 180px;
        padding: 12px;
    }

    .mt-50 {
        margin-top: 25px !important;
    }

    .mt-100 {
        margin-top: 50px !important;
    }

    .mt-130 {
        margin-top: 70px !important;
    }

    .nimalaan-main-section {
        margin-top: 50px !important;
    }

    .nimalaan_image_section_content {
        padding-left: 30px;
        padding-top: 30px;
    }

    .social-media {
        padding-left: 10px !important;
    }

    .our-team-card-content {
        padding: 8px;
    }
}

@media screen and (min-width:1000px) and (max-width:1200px) {
    .ourmission-row .offset-md-2 {
        margin-left: 0px !important;
    }

    .ourmission-row .col-lg-5 {
        width: 45% !important;
    }

    .nimalaan_banner {
        height: 500px;
    }

    .clients_card_col img {
        height: 55px !important;
    }

    .nimalaan_image_section {
        height: 610px;
    }

    .clients_card {
        height: 126px;
    }

    .mt-130 {
        margin-top: 60px !important;
    }

    .ourservie_img {
        height: 422px !important;
        object-fit: cover;
        border-radius: 20px;
    }

    .our-service-card {
        height: 410px !important;
    }

    .contact_card svg {
        height: 55px;
        width: 55px;
    }

    .contact_card h5 {
        font-size: 18px;
    }

    .service {
        object-fit: cover;
        border-radius: 10px;
        height: 100%;
    }

    .service_page_card_image img {
        object-fit: cover;
        border-radius: 10px;

    }

    .nimalaan-main-section {
        margin-top: 50px !important;
    }

    div.whatwedo_card_about {

        height: 100% !important;
    }

    div.achievement_card {
        height: 100% !important;
    }

    div.achievement_card.space {
        height: 100% !important;
    }

    .job_card {
        height: 100%;
    }

    .job_keys {
        gap: 16px;

    }

    .job_keys_cont p {
        font-size: 16px;
    }

    .apply_now_btn,
    .detail_button {
        width: 100%;
        display: block;

    }

    .detail_button {
        margin-left: 0px !important;
    }

    .apply_now_btn,
    .detail_button {
        margin-top: 12px;
    }
}

@media screen and (max-width:993px) {
    .nimalaan_banner.type_2 {
        height: 100% !important;
    }

    .image-card-main {
        line-height: 28px;
    }

    .nimaalan-powering-banner-image_type_2_image {
        height: 350px;
        margin: auto;
        object-fit: cover;
    }

    .navber_custom {
        padding: 0px 10px;
    }

    .nimalaan-logo {
        height: 50px;
        width: 90px;
    }

    .navbar-nav .nav-link {
        margin-top: 5px;
    }

    .navbar-join {
        margin-left: 10px;
        margin-top: 20px;
    }

    .nimalaan_banner_inner_section .mt-130 {
        margin-top: 55px;
    }

    .nimaalan-powering-banner-image {
        position: absolute;
        top: 70% !important;
        left: 50% !important;
        height: 400px !important;
        transform: translate(-50%, -50%);
    }

    .nimalaan_banner_inner_section h1 {
        line-height: 44px;
    }

    .nimalaan_banner {
        height: 680px !important;
    }

    .order_last {
        order: 3 !important;
    }

    .innovation_card.mt-100 {
        margin-top: 0px !important;
    }

    .oreder-change-row .col-lg-4 {
        margin-bottom: 20px;
    }

    .oreder-change-row .col-lg-4:nth-child(2) {
        /* order: -1 !important;
        */
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .map_card {
        margin: 20px auto;
        margin-top: 50px;
    }

    .our-service-card {
        height: auto !important;
    }

    .our-service-card-content {
        padding: 18px;
    }

    .color-shade {
        height: 370px;
    }

    .ourservie_img {
        height: 450px;
    }

    .pb-75 {
        padding-bottom: 35px;
    }

    .pt-75 {
        padding-top: 35px;
    }

    .our-mission {
        margin-top: 70px;
    }

    .our-achievements {
        padding-left: 0px;
    }

    .achievement_card {
        margin-bottom: 18px;
    }

    .whatwedo_card_about {
        margin-bottom: 18px;
    }

    .nimalaan_image_section {
        height: 100%;
    }

    .nimalaan_image_section_content {
        padding: 20px;
        padding-top: 40px;
    }

    .join-now-card {
        padding: 25px 20px;
    }

    .form-control,
    .form-select {
        padding: 12px 15px;
    }

    .mb-50 {
        margin-bottom: 35px;
    }

    .primary-btn.mt-5 {
        margin-top: 20px !important;
    }

    .contact_card {
        margin-bottom: 20px;
    }

    .footer-logo {
        width: 100px;
        height: 60px;
    }

    .links,
    .social-media,
    .contact {
        margin-top: 32px;
    }

    .social-media {
        padding-left: 0px !important;
    }

    .address_box svg {
        flex-basis: auto !important;
        height: 40px;
        width: 30px;
        color: #4B4840 !important;
    }

    .address_box:first-child svg {
        height: 40px;
        width: 40px;
    }

    .address_box {
        gap: 20px;
    }

    .copy-rights {
        padding: 22px 0px;
        flex-direction: column;
        gap: 10px;
    }

    .our-mission.pl-40 {
        padding-left: 0px !important;
    }

    .service.pl-50 {
        padding-left: 0px;
    }

    .job_card {
        padding: 26px;
        height: 100%;
    }

    .job_keys {
        /* flex-direction: column;
    align-items: flex-start; */
        gap: 12px;
        flex-wrap: wrap;
    }

    .apply_now_btn,
    .detail_button {
        width: 100%;
        display: block;

    }

    .detail_button {
        margin-left: 0px !important;
    }

    .apply_now_btn,
    .detail_button {
        margin-top: 12px;
    }

    .job_description {
        text-align: justify;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
        overflow: hidden;
    }

    .jobs_section h1,
    .apply_now_title {
        margin-bottom: 20px;
        line-height: 30px;
    }

    .job_keys_cont {
        margin-top: 8px;
        margin-bottom: 0;
    }

    .job_column {
        margin-bottom: 16px;
    }

    .carrier_banner_content h1 {
        line-height: 50px;
    }

    .upload_container p {
        font-size: 14px;
    }

    .upload_container {
        margin-bottom: 20px;
        height: 80px;
        padding: 16px;
    }

    .upload_container img {
        height: 50px;
        width: 35px;
    }

    .custom_beadcrumb {
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .carrier_banner {
        height: 190px;
    }

    .job_overview,
    .job_view_description,
    .job-info-section {
        margin-top: 30px;
    }

    .job-view-btn {
        margin-top: 20px;
    }

    .job-info-overview {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        margin-top: 20px;

    }

    .jobs_section {
        padding: 0px;
    }

    .event_cont{
        min-height: 260px;
    }

    .event-detail-banner{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width:768px) {
.job_description{
    text-align: justify;
     display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
}
.jobs_section h1 , .apply_now_title{
    margin-bottom: 20px;
    line-height: 30px;
}
.job_keys_cont {
    margin-top: 8px;
}
.job_column{
    margin-bottom: 16px;
}
.carrier_banner_content h1 {
    line-height: 50px;
}
.upload_container p {
    font-size: 12px;
}
.upload_container{
    margin-bottom: 20px;
    height: 90px;
    padding: 16px;
}
.upload_container img {
    height: 50px;
    width: 35px;
}
.custom_beadcrumb {
    margin-top: 20px;
    margin-bottom: 25px;
}
.carrier_banner {
    height: 190px;
}
.job_overview, .job_view_description, .job-info-section{
    margin-top: 30px;
}
.job-view-btn{
    margin-top: 20px;
}
.job-info-overview{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    margin-top: 20px;
   
}
.jobs_section{
    padding: 0px;
}
.text-crop{
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}
.clear_resume{
    top: -5px;
}
.uploaded_file{
    margin-top: 7px;
}
.uploaded_file strong{
    display: none;
}
.chip_design{
    margin-left: 0px;
    padding: 2px 15px;
}
}
@media  screen and (max-width:768px){
    div.whatwedo_card_about {

        height: 135px !important;
    }

    div.achievement_card {
        height: 135px !important;
    }

    div.achievement_card.space {
        height: fit-content !important;
    }
}

@media screen and (min-width: 600px) and (max-width:993px) {
    .achievement_card {
        height: 100%;
    }

    .innovation_card h2 {
        font-size: 22px;
    }

    .innovation_card h6 {
        font-size: 16px;
    }

    .innovation_card {
        padding: 16px;
        height: 150px;
    }

    .our-service-card-content h3 {
        font-size: 18px;
        line-height: normal;
    }

    .our-service-card-content ul li {
        font-size: 13px;
    }

    .nimalaan-main-section {
        margin-top: 70px !important;
    }

    .nimalaan-main-section.pb-75 {
        padding-bottom: 0px;
    }

    .ourservie_img {
        height: 340px;
    }

    .color-shade {
        height: 415px;
    }

    .whatwedo_card_about {
        height: 100% !important;

    }

    .service {
        object-fit: cover;
        border-radius: 10px;
        width: 100%;
        height: 100%;
    }

    .contact_card p {
        font-size: 14px;
        margin-top: 14px;
    }

    .contact_card h5 {
        font-size: 16px;
        margin-top: 10px;
    }

    .contact_card svg {
        height: 33px;
        width: 33px;
    }

    .contact_card {
        height: 200px;
    }
    
    .featured-events{
        padding: 14px !important;
    }

}

/* for mobile */
@media screen and (max-width:619px) {
    .nimalaan-logo {
        height: 43px !important;
        width: 80px !important;
    }

    .navber_custom {
        height: 65px !important;
    }

    .nimalaan_custom_nav {
        top: 0px;
    }

    .navber_custom {
        border-radius: 10px;
    }

    .nimaalan-powering-banner-image {
        position: absolute;
        top: 75% !important;
        left: 50% !important;
        height: 300px !important;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    .nimalaan_banner_inner_section .mt-130 {
        margin-top: 20px !important;
    }

    .nimalaan_banner_inner_section h1 {
        line-height: 44px;
    }

    .clients_card {
        height: 90px;
        overflow: hidden;
        margin-top: 18px;
    }

    .clients_card .clients_card_row .clients_card_col img {
        height: 28px !important;
    }

    .clients_card_row {
        padding: 12px 0px;
    }

    .innovation_card {
        height: 160px !important;
        padding: 12px;
    }

    .innovation_card.mt-100 {
        margin-top: 0px !important;
    }

    .oreder-change-row .col-lg-4 {
        margin-bottom: 20px;
    }

    .oreder-change-row .col-lg-4:nth-child(2) {
        order: -1 !important;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .ourservie_img {
        height: 292px;
    }

    .nimalaan_image_section_content {
        padding: 20px;
        padding-top: 40px;
    }

    .nimalaan_product_management {
        display: block;
    }

    .nimalaan_product_management svg {
        height: 80px;
        width: 80px;
    }

    .contact_card p {
        font-size: 14px;
        margin-top: 14px;
    }

    .contact_card h5 {
        font-size: 16px;
        margin-top: 10px;
    }

    .contact_card svg {
        height: 33px;
        width: 33px;
    }

    .contact_card {
        height: 200px;
    }

    .pd-0_8 {
        padding: 0px 8px;
    }

    .nimalaan-main-section.card_sec {
        margin-top: 50px !important;
    }

    .nimalaan-main-section.pb-75 {
        padding-bottom: 0px;
    }

    .map_card {
        margin-top: 30px;
    }

    .image-card-title {
        margin-bottom: 0px;
    }

    .map_card_img {
        margin-top: 10px;
    }

    .featured-events h1 {
        margin-top: 0 !important;
        margin-bottom: 20px !important;
    }

    .event_banner {
        height: 200px;
        border-radius: 12px;
    }

    .event-content {
        margin-top: 14px;
    }

    .event-descrp {
        margin: 0;
    }

    .event-gallery {
        padding-top: 10px;
    }

    .featured-events{
        padding: 14px !important;
    }
    .event_keys {
        margin: 12px 0 !important;
    }
    .event-gallery h1{
        padding-top: 10px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:400px) {
    .clients_card .clients_card_row .clients_card_col img {
        height: 20px !important;
    }

    .clients_card {
        height: 70px;
    }

    .contact_info.col-lg-6 {
        width: 100% !important;
    }

    div.whatwedo_card_about {

        height: 135px !important;
    }

    div.achievement_card {
        height: 150px !important;
    }
}