.stepperdir{
    flex:0 2 1;
    width: 20%;
}
.historyheading{
    margin-left: 16px !important;
}
.historylabel{
    font-family: "Heebo", sans-serif !important;
    font-weight: 300 !important;
    background: none !important;
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem) !important;
    
}
.MuiStep-root .MuiStepLabel-labelContainer{
    margin-left: 10px;
    
}
.MuiBox-root {
    height: fit-content !important;
}
.history-card-main{
    font-family: "Heebo", sans-serif;
    font-weight: 700;
    font-size: clamp(1.25rem, 0.9211rem + 1.7544vi, 2.5rem);
    color: #021E39;
    margin-top: 30px;
    line-height: 60px;
}
.stepnone text{
    display: none;
}
.stepnone circle:active{
    color: #1A6CB6 !important;
}
.stepIconText {
    display: none;
  }
 .MuiStepLabel-iconContainer.Mui-completed svg{
    background-color: #000000 !important;
    border-radius: 50%;
 }
 .MuiStepConnector-line.MuiStepConnector-lineVertical.MuiStepConnector-line {
    display: block;
    border-color: #000000;
    border-left-style: solid;
    border-left-width: 3px;
    min-height: 30px;
    margin-left: -2px;
}
.MuiStepLabel-root.MuiStepLabel-vertical.MuiStepLabel-root{
    padding: 2px 0;
}
.MuiStepLabel-label.Mui-disabled.MuiStepLabel-label{
    color: "#000000";
    font-weight:400;
    line-height:24px;
    font-family:var(--heebo);
    line-height: 24px;
}
.MuiSvgIcon-root.MuiStepIcon-root {
    color: black;
}

 @media only screen and (min-width: 1000px) {
    .our_history{
        margin-left: 0px !important;
    }
    .MuiStepLabel-label.Mui-disabled.MuiStepLabel-label {
         font-size: 18px !important ;
         font-weight: 400px !important;
         line-height: 24px;
    }
    .MuiStepConnector-line.MuiStepConnector-lineVertical.MuiStepConnector-line {
        height: 45px;
    }
    
  }
  /* @media screen and (min-width:490px) and (max-width:970px) {
    .our_history{
        margin-top: -130px !important;
      }
  } */
 
  @media only screen and (max-width: 500px) {
    .MuiStepLabel-root .Mui-active{
        font-size:15px !important;
        overflow: hidden;
      }
      .MuiStepConnector-line.MuiStepConnector-lineVertical.MuiStepConnector-line {
        height: 60px;
    }
    .MuiStep-root .MuiStepLabel-labelContainer {
        margin-left: 0px !important;
    }
  }

  @media only screen and (max-width: 993px) {
  .MuiStepConnector-line.MuiStepConnector-lineVertical.MuiStepConnector-line{
    margin-left: 1px !important;
}
.our_history img{
    width: 100%;
}
.our-team-card-content h3 {
    font-size: 16px !important;
}

.our-team-card-content p {
    font-size: 13px !important;
}
.MuiStepConnector-line.MuiStepConnector-lineVertical.MuiStepConnector-line {
    height: 35px;
}

  }
  @media only screen and (max-width: 630px) {
  .history_title{
    margin-top: 15px !important;
}
  }
  @media only screen and (max-width: 325px) {
    .MuiStepLabel-root .Mui-active{
      font-size: 12px !important;
  }
    }