.achievement-title{
    font-size:clamp(1.375rem, 1.1178rem + 1.3719vi, 2.3525rem);
    font-weight: 500;
    color: #53A930;
    font-family: 'Heebo', sans-serif;
    line-height: 55.28px;
    margin-bottom: 8px;
}
.achievement-project{
    font-size: clamp(0.9375rem, 0.7862rem + 0.807vi, 1.5125rem);
    font-weight: 700;
    line-height: 35.54px;
    color: #021E39;
    font-family: 'Heebo', sans-serif;
    margin-bottom: 5px;
}
.achievement-content{
    font-size: clamp(0.875rem, 0.7599rem + 0.614vi, 1.3125rem);
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #424966;
    line-height: 32.62px;
    margin-bottom: 0px;
}
.wind-project{
   font-size: clamp(0.875rem, 0.7434rem + 0.7018vi, 1.375rem);
   font-weight: 400;
   line-height: 32.26px;
   color: #424966;
   font-family: "Roboto", sans-serif;
}
.wind-project span{
    font-weight: 700;
    font-size: clamp(0.875rem, 0.7434rem + 0.7018vi, 1.375rem);
    line-height: 32.26px;
    color: #424966;
}
.achievement-item{
    width: 80%;
}

.border-right {
    border-right: 2px solid #E8EFE6;
}

@media screen and (max-width:993px){
    
    .border-right {
        border: none !important;
    }
    .achievement-div{
        border: 2px solid #E8EFE6 !important;
    }
}
@media screen and (max-width:993px){
    .achievement-item{
        width: 90%;
    }
}