
.service_page_card{
    display: flex;
    gap: 30px;
    margin-top: 40px;
   
    
}
.service_page_card_image button {
    margin-top: 50px !important;
}
.service_page_card_content{
    display: flex;
    flex-direction: column;
    gap: 20px;
  
}
.service_page_card_content{
    flex-basis: 35%;
}

.service_page_card_image{
    flex-basis: 30%;
}
.service_page_card_image img{

    height: 624px;
}

.service_page_card_small h2{
    font-family: "Heebo" , sans-serif;
    font-size: clamp(1.125rem, 1.0263rem + 0.5263vi, 1.5rem);
    font-weight: 600;
    color: #021E39;
}

.service_page_card_small{
    width: 100%;
    background-color: #fff;
    height: 240px;
    padding: 22px;
    border: 1px solid #CCEAC4;
    border-radius: 10px;
}
.service_page_card_small p, .service_page_card_small ul {
    margin-top: 16px;

}
.service_page_card_small p, .service_page_card_small ul li {

    font-size: clamp(0.9375rem, 0.9046rem + 0.1754vi, 1.0625rem);
    font-family: "Lato", sans-serif;
    font-weight: 400;
    color:  #424966;
    text-align: justify;
    /* margin-top: 8px; */
}
.tex_wrap .nimalaan_banner_inner_section p{
    width: 90% !important;
}

@media screen and (min-width: 1100px) and (max-width: 1490px) {
    .service_page_card_small h2 {
        font-size: 22px;
    }
    .service_page_card_small p, .service_page_card_small ul li {
        font-size: 16px;
    }
    .service_page_card_small {
        height: 100%;
    }
}

@media screen and (min-width: 992px) and (max-width:1056px) {
    .service_page_card_small h2 {
        font-size: 18px;
    }
    .service_page_card_small p, .service_page_card_small ul li {
        font-size: 16px;
    }
    .service_page_card_small {
        height: 315px;
    }
    .service_page_card{
        align-items: center;
    }
    .service_page_card_image img{
        object-fit: cover;
        border-radius: 10px;
    }

}

@media screen and (max-width:993px) {
    .service_page_card {
        flex-direction: column;
    }
    .service_page_card_content {
        flex-direction: row;
    }
    .service_page_card_image img{
        display: none;
    }
    .service_page_card_image button {
        margin-top: 20px !important;
    }
    .tex_wrap .nimalaan_banner_inner_section p{
        width: 100% !important;
    }
}
@media screen and (min-width:620px) and (max-width:765px) {
    .service_page_card_small {
        flex-basis: 45%;
    }
    .service_page_card_content{
        flex-wrap: wrap;
    }
    .service_page_card_small:last-child {
        flex-basis: 100%;
        height: 165px !important;
        
    }

}
@media screen and (min-width:600px) and (max-width:1000px) {
    .service_page_card_small h2 {
        font-size: 15px;
    }
    .service_page_card_small p, .service_page_card_small ul li {
        font-size: 14px;
    }
    .service_page_card_small {
        /* height: 100%; */
        height: 315px !important;
        padding: 18px;
    }
    .service_page_card_image {
        order: 3;
    }
}

@media screen and (max-width:619px) {
    .service_page_card{
        flex-direction: column;
        gap: 16px;
        margin-top: 20px;

    }
    .service_page_card_content {
        flex-direction: column;
    }
    .service_page_card_small{
        height: 100% !important;
        padding: 18px;
    }
    .service_page_card_image{
        order: 3;
    }
    .service_page_card_image img{
        display: none;
    }
    .service_page_card_image button {
        margin-top: 20px !important;
    }
    .nimalaan-section-title {
        margin-bottom: 20px;
    }
    .nimaalan-powering-banner-image{
        position: absolute;
        top: 63% !important;
        height: 300px !important;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}

